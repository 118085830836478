import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"400"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c('custom-snackbar',{ref:"snackbar"}),_c(VCard,[_c(VToolbar,{attrs:{"color":"info","dark":""}},[_c('span',{staticClass:"headline"},[_vm._v("Entrega Total")])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12 mt-5 mt-0 p-0"}},[_c('DatePicker',{attrs:{"label":"Data Entrega","max":_vm.$date.getISOToday()},model:{value:(_vm.dataEntregaTotal),callback:function ($$v) {_vm.dataEntregaTotal=$$v},expression:"dataEntregaTotal"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.model = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.confirmarEntregaTotal}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }