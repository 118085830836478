import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-file-eye-outline ")])],1)]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('custom-snackbar',{ref:"snackbar"}),_c(VForm,{ref:"formEditarEntrega"},[_c(VCard,[_c(VToolbar,{attrs:{"color":"info","dark":""}},[_c('span',{staticClass:"headline"},[_vm._v("Editar Entrega do item "+_vm._s(_vm.item.produto))])]),_c(VCardText,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12 my-0 p-0"}},[_c(VDataTable,{attrs:{"headers":_vm.headersEditarEntrega,"items":_vm.itens,"items-per-page":-1,"hide-default-footer":"","dense":"","loading-text":_vm.$dataTableConfig.getLoadText(),"loading":_vm.loading},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.data_entregue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$stringFormat.dateISOToBRL(item.data_entregue))+" ")]}},{key:"item.quantidade_solicitada",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$stringFormat.formatNumber(_vm.item.quantidade_solicitada, 2))+" ")]}},{key:"item.quantidade_entregue",fn:function(ref){
var item = ref.item;
return [_c(VTextField,{attrs:{"type":"number","reverse":"","dense":"","disabled":_vm.disabledEdicaoEntrega,"rules":_vm.quantidadeEntregueRule},model:{value:(item.quantidade_entregue),callback:function ($$v) {_vm.$set(item, "quantidade_entregue", $$v)},expression:"item.quantidade_entregue"}})]}},{key:"item.actions",fn:function(ref){return [(_vm.$auth.hasPermission('sapiens-solicitacao-compras.editar-itens-agricola'))?_c(VIcon,{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){_vm.disabledEdicaoEntrega = false}}},[_vm._v("mdi-pencil")]):_vm._e()]}}],null,true)})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.salvaEdicao}},[_vm._v(" Salvar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }