<template>
  <v-dialog v-model="model" max-width="400">
    <custom-snackbar
        ref="snackbar"
    />
    <v-card>
      <v-toolbar color="info" dark>
        <span class="headline">Entrega Total</span>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12 mt-5 mt-0 p-0">
              <DatePicker
                  label="Data Entrega"
                  v-model="dataEntregaTotal"
                  :max="$date.getISOToday()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            color="blue darken-1"
            text
            @click="model = false"
        >
          Cancelar
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="confirmarEntregaTotal"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    compra: Object,
  },
  data: (instance) => {
    return {
      dataEntregaTotal: instance.$date.getISOToday(),
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    confirmarEntregaTotal () {
      this.model = false

      this.$refs.snackbar.show('Requisitando', 'Confirmando entrega dos itens', 'default', -1, true)

      const form = {
        data_entrega: this.dataEntregaTotal,
        produtos: [],
        entrega_total: true,
      }

      ApiService
        .post('/compras/produtos/' + this.compra.compras_id + '/entregas', form)
        .then((res) => {
          this.$refs.snackbar.close()

          if (!res.data.success) {
            this.$refs.snackbar.show('Entrega não pode ser efetuada', res.data.mensagem, 'warning', 3000, false)
            return
          }

          this.$emit('entregue')
          this.$refs.snackbar.show('Entrega salva com sucesso', res.data.mensagem, 'success', 3000, false)
        })
        .catch(() => {
          this.$emit('entregue')
          this.$refs.snackbar.close()
          this.$refs.snackbar.show('Entrega não pode ser efetuada', 'Problema na comunicação', 'danger', 3000, false)
        })
    },
  },
}
</script>
