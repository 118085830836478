<template>
  <v-dialog v-model="model" max-width="800">
    <custom-snackbar
        ref="snackbar"
    />
    <v-form ref="form">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Entrega Parcial</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12 my-0 p-0">
                <v-data-table
                    fixed-header
                    :headers="headersEntregaParcial"
                    :items="itens"
                    :items-per-page="-1"
                    hide-default-footer
                    dense
                >
                  <template v-slot:[`item.quantidade_solicitada`]="{ item }">
                    {{ $stringFormat.formatNumber(item.quantidade_solicitada, 2) }}
                  </template>

                  <template v-slot:[`item.saldo`]="{ item }">
                    {{ $stringFormat.formatNumber(calculaSaldo(item), 2) }}
                  </template>

                  <template v-slot:[`item.quantidade_entregue`]="{ item }">
                    {{ $stringFormat.formatNumber(item.quantidade_entregue, 2) }}
                  </template>

                  <template v-slot:[`item.quantidade_entrega`]="{ item }">
                    <v-text-field
                        v-model="item.quantidade_entrega"
                        type="number"
                        reverse
                        dense
                        :disabled="calculaSaldo(item) === 0"
                        :rules="quantidadeEntregueRule(item)"
                    />
                  </template>
                </v-data-table>
              </v-col>
              <v-col cols="8"></v-col>
              <v-col cols="4 mt-5 mt-0 p-0">
                <DatePicker
                    label="Data Entrega"
                    v-model="dataEntregaParcial"
                    :max="$date.getISOToday()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="model = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="confirmarEntregaParcial"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    compra: Object,
    itens: Array,
  },
  data: (instance) => {
    return {
      dataEntregaParcial: instance.$date.getISOToday(),
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    headersEntregaParcial () {
      return [
        { text: 'Produto', value: 'produto', sortable: false },
        { text: 'Solicitado', value: 'quantidade_solicitada', align: 'right', sortable: false },
        { text: 'Saldo', value: 'saldo', align: 'right', sortable: false },
        { text: 'Total Entregue', value: 'quantidade_entregue', align: 'right', sortable: false },
        { text: 'Entregue', value: 'quantidade_entrega', align: 'right', sortable: false },
      ]
    },
  },
  methods: {
    confirmarEntregaParcial () {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.model = false

      this.$refs.snackbar.show('Requisitando', 'Confirmando entrega dos itens', 'default', -1, true)

      const produtos = this.itens
        .filter((item) => parseFloat(item.quantidade_entrega) > 0)
        .map(function (item) {
          return {
            sequencia_item: item.sequencia_item,
            quantidade: parseFloat(item.quantidade_entrega).toFixed(2),
            produto_id: item.produto_id,
          }
        })

      const form = {
        data_entrega: this.dataEntregaParcial,
        produtos,
        entrega_total: false,
      }

      ApiService
        .post('/compras/produtos/' + this.compra.compras_id + '/entregas', form)
        .then((res) => {
          this.$refs.snackbar.close()

          if (!res.data.success) {
            this.$refs.snackbar.show('Entrega não pode ser efetuada', res.data.mensagem, 'warning', 3000, false)
            return
          }

          this.$emit('entregue')
          this.$refs.snackbar.show('Entrega salva com sucesso', res.data.mensagem, 'success', 3000, false)
        })
        .catch(() => {
          this.$emit('entregue')
          this.$refs.snackbar.close()
          this.$refs.snackbar.show('Entrega não pode ser efetuada', 'Problema na comunicação', 'danger', 3000, false)
        })
    },
    calculaSaldo (item) {
      return parseFloat(item.quantidade_solicitada) - parseFloat(item.quantidade_entregue ?? 0)
    },
    quantidadeEntregueRule (item) {
      if (this.calculaSaldo(item) === 0) {
        return []
      }

      return [
        (v) => !v ||
            parseFloat(v) === 0 ||
            (parseFloat(v) <= this.calculaSaldo(item) && parseFloat(v) > 0) ||
            'Quantidade inválida',
      ]
    },
  },
}
</script>
