<template>
  <v-dialog v-model="show" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
      >
        <v-icon small class="mr-2">
          mdi-file-eye-outline
        </v-icon>
      </v-btn>
    </template>
    <custom-snackbar
        ref="snackbar"
    />
    <v-form ref="formEditarEntrega">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Editar Entrega do item {{ item.produto }}</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12 my-0 p-0">
                <v-data-table
                    :headers="headersEditarEntrega"
                    :items="itens"
                    :items-per-page="-1"
                    hide-default-footer
                    dense
                    :loading-text="$dataTableConfig.getLoadText()"
                    :loading="loading"
                >
                  <template v-slot:progress>
                    <v-progress-linear
                        absolute
                        indeterminate
                        color="green"
                    />
                  </template>
                  <template v-slot:[`item.data_entregue`]="{ item }">
                    {{ $stringFormat.dateISOToBRL(item.data_entregue) }}
                  </template>

                  <template v-slot:[`item.quantidade_solicitada`]="{ }">
                    {{ $stringFormat.formatNumber(item.quantidade_solicitada, 2) }}
                  </template>

                  <template v-slot:[`item.quantidade_entregue`]="{ item }">
                    <v-text-field
                        v-model="item.quantidade_entregue"
                        type="number"
                        reverse
                        dense
                        :disabled="disabledEdicaoEntrega"
                        :rules="quantidadeEntregueRule"
                    />
                  </template>
                  <template v-slot:[`item.actions`]="{ }">
                    <v-icon v-if="$auth.hasPermission('sapiens-solicitacao-compras.editar-itens-agricola')" small class="mr-2" @click="disabledEdicaoEntrega = false"
                    >mdi-pencil</v-icon
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="show = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="salvaEdicao"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    item: Object,
  },
  data: (instance) => {
    return {
      show: false,
      dataEntregaTotal: instance.$date.getISOToday(),
      itens: [],
      loading: false,
      disabledEdicaoEntrega: true,
      quantidadeEntregueRule: [
        (v) => {
          if (!v || v === 'NaN') {
            return 'Necessário informar a Quantidade'
          }

          if (parseFloat(v) === 0) {
            return 'Quantidade precisa ser maior que 0'
          }

          if (instance.totalEntregue > instance.item.quantidade_solicitada) {
            return 'Quantidade entregue total maior que o saldo'
          }

          if (parseFloat(v) > 0) {
            return true
          }

          return 'Quantidade precisa ser maior que 0'
        },
      ],
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.getItens()
      }
    },
  },
  computed: {
    totalEntregue () {
      return this.itens.reduce((partialSum, item) => parseFloat(partialSum) + parseFloat(item.quantidade_entregue), 0)
    },
    headersEditarEntrega () {
      return [
        { text: 'Data Entrega', value: 'data_entregue' },
        { text: 'Usuário', value: 'usuario', align: 'right', sortable: false },
        { text: 'Solicitado', value: 'quantidade_solicitada', align: 'right', sortable: false },
        { text: 'Entregue', value: 'quantidade_entregue', align: 'right', sortable: false },
        { text: 'Editar', value: 'actions' },
      ]
    },
  },
  methods: {
    getItens () {
      this.loading = true
      this.itens = []

      ApiService
        .get('compras/produtos/' + this.item.compras_id + '/entregas/' + this.item.produto_id)
        .then((response) => {
          this.itens = response.data.data

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    salvaEdicao () {
      if (!this.$refs.formEditarEntrega.validate()) {
        return true
      }

      this.show = false

      this.$refs.snackbar.show('Aguarde', 'Confirmando Edição dos itens', 'success', -1, true)

      ApiService.put('compras/entregas/produtos', {
        entrega_produtos: this.itens.map((item) => {
          return {
            entrega_produto_id: item.entrega_produto_id,
            quantidade_entregue: item.quantidade_entregue,
          }
        }),
      })
        .then((res) => {
          this.$refs.snackbar.close()

          if (!res.data.success) {
            this.$refs.snackbar.show('Entrega não pode ser efetuada', res.data.mensagem, 'warning', 3000, false)
            return
          }

          this.$emit('entregue')
          this.$refs.snackbar.show('Entrega salva com sucesso', res.data.mensagem, 'success', 3000, false)
        })
        .catch(() => {
          this.$emit('entregue')
          this.$refs.snackbar.show('Entrega não pode ser efetuada', 'Problema na comunicação', 'danger', 3000, false)
        })
    },
  },
}
</script>
