<template>
  <div>
    <FiltroMobile :has-change="change" @search="seachFilter">
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker label="Data Inicial" v-model="dataInicial" :disabled="typeof codigoOC === 'string' && codigoOC !== '' || typeof codigoSC === 'string' && codigoSC !== ''"/>
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker label="Data Final" v-model="dataFinal" :disabled="typeof codigoOC === 'string' && codigoOC !== '' || typeof codigoSC === 'string' && codigoSC !== ''"/>
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-3">
        <EmpresasSelect v-model="empresa" @change="change = true" mostra-todos-label></EmpresasSelect>
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-3">
        <ProducoesSelect v-model="producao" :empresa="empresa" :disabled="!empresa" administrativo @change="change = true" mostra-todos-label></ProducoesSelect>
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            dense
            outlined
            label="Estágio da Compra"
            item-text="nome"
            item-value="id"
            v-model="estagioCompra"
            readonly
            :items="estagioCompraOptionsFilter"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            dense
            outlined
            label="Possui Cotação"
            v-model="possuiCotacao"
            :items="possuiCotacaoOptionsFilter"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            dense
            outlined
            label="Situação"
            v-model="situacao"
            :items="situacaoOptionsFilter"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-text-field
            dense
            outlined
            label="Solicitação de Compra"
            v-model="codigoSC"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-text-field
            dense
            outlined
            label="Ordem de Compra"
            v-model="codigoOC"
            @change="change = true"
        />
      </v-col>
      <template v-slot:outros>
        <v-col class="pb-0 col-12">
          <v-select
              dense
              outlined
              label="Unidade de Negócio"
              item-text="nome"
              item-value="id"
              v-model="unidadeNegocio"
              :items="unidadeNegocioOptionsFilter"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12">
          <ProdutoSelect v-model="produto" :empresa="empresa" :producao="producao" text-option @change="change = true"/>
        </v-col>
        <v-col class="pb-0 col-12">
          <FornecedorSelect v-model="fornecedor" :empresa="empresa" :producao="producao" text-option @change="change = true"/>
        </v-col>
        <v-col class="pb-0 col-12">
          <v-text-field
              dense
              outlined
              label="Comprador"
              v-model="comprador"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12">
            <v-text-field
                    dense
                    outlined
                    label="Numero da Nota"
                    v-model="notaFiscal"
                    @change="change = true"
            />
        </v-col>
          <v-col class="pb-0 col-12">
              <v-select
                      dense
                      outlined
                      label="NF Lançada"
                      v-model="lancadoNota"
                      :items="lancadoNotasOptionsFilter"
                      @change="change = true"
              />
          </v-col>
        <v-col class="pb-0 col-12">
          <v-text-field
              dense
              outlined
              label="Observação"
              v-model="observacao"
              @change="change = true"
          />
        </v-col>
      </template>
    </FiltroMobile>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => {
    return {
      change: false,
      empresa: '',
      producao: '',
      unidadeNegocio: 0,
      estagioCompra: 2,
      possuiCotacao: 'T',
      situacao: 1,
      dataInicial: '',
      dataFinal: '',
      codigoOC: '',
      codigoSC: '',
      produto: '',
      fornecedor: '',
      comprador: '',
      observacao: '',
      notaFiscal: '',
      lancadoNota: '',
      lancadoNotasOptionsFilter: [
        { value: '', text: 'Todas' },
        { value: 2, text: 'NF Lan. Total' },
        { value: 3, text: 'NF Lan. Parcial' },
        { value: 1, text: 'NF Não Lançada' },
      ],
      unidadeNegocioOptionsFilter: [
        { id: 0, nome: 'Todas' },
        { id: 4, nome: 'Administrativa' },
        { id: 2, nome: 'Cana-de-Açucar' },
        { id: 3, nome: 'Comercialização' },
        { id: 1, nome: 'Grama' },
        { id: 5, nome: 'Grãos' },
      ],
      estagioCompraOptionsFilter: [
        { id: 0, nome: 'Ordem de Compra' },
        { id: 1, nome: 'Solicitação de Compra' },
        { id: 2, nome: 'Todos' },
      ],
      possuiCotacaoOptionsFilter: [
        { value: 'T', text: 'Todas' },
        { value: 'C', text: 'Sim' },
        { value: 'S', text: 'Não' },
      ],
      situacaoOptionsFilter: [
        { value: 1, text: 'Todas' },
        { value: 4, text: 'Aprovada' },
        { value: 14, text: 'Aprovada N1' },
        { value: 15, text: 'Aprovada N2' },
        { value: 11, text: 'Comprada' },
        { value: 20, text: 'Cancelada' },
        { value: 13, text: 'Cotada' },
        { value: 9, text: 'Cotando' },
        { value: 18, text: 'Ent. Parcial' },
        { value: 19, text: 'Ent. Total' },
        { value: 12, text: 'Executadas' },
        { value: 2, text: 'Minhas Cotações' },
        { value: 3, text: 'Pend. Aprovação' },
        { value: 8, text: 'Pend. Cotação' },
        { value: 5, text: 'Reprovada' },
        { value: 10, text: 'Validada' },
        { value: 16, text: 'Valida N1' },
        { value: 17, text: 'Valida N2' },
      ],
    }
  },
  mounted () {
    this.empresa = this.value?.empresa ?? ''
    this.producao = this.value?.producao ?? ''
    this.unidadeNegocio = this.value?.unidadeNegocio ?? 0
    this.estagioCompra = parseInt(this.value?.estagioCompra) ?? 2
    this.possuiCotacao = this.value?.possuiCotacao ?? 'T'
    this.situacao = this.value?.situacao ?? 1
    this.dataInicial = this.value?.dataInicial
    this.dataFinal = this.value?.dataFinal
    this.codigoOC = this.value?.codigoOC ?? ''
    this.codigoSC = this.value?.codigoSC ?? ''
    this.produto = this.value?.produto ?? ''
    this.fornecedor = this.value?.fornecedor ?? ''
    this.comprador = this.value?.comprador ?? ''
    this.observacao = this.value?.observacao ?? ''
    this.notaFiscal = this.value?.notaFiscal ?? ''
    this.lancadoNota = this.value?.lancadoNota ?? ''
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  watch: {
    'value.dataInicial' (newValue, oldValue) {
      this.dataInicial = newValue
    },
    'value.dataFinal' (newValue, oldValue) {
      this.dataInicial = this.value?.dataInicial
      this.dataFinal = newValue
    },
    situacao (newValue, oldValue) {
      switch (newValue) {
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19:
          this.estagioCompra = 0 // ORDEM
          break
        case 2:
        case 8:
        case 9:
          this.estagioCompra = 1 // SOLICITAÇÃO
          break
        default:
          this.estagioCompra = 2 // TODOS
          break
      }
    },
  },
  methods: {
    seachFilter () {
      this.model = {
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal,
        empresa: this.empresa,
        producao: this.producao,
        unidadeNegocio: this.unidadeNegocio,
        estagioCompra: this.estagioCompra,
        possuiCotacao: this.possuiCotacao,
        situacao: this.situacao,
        codigoOC: this.codigoOC,
        codigoSC: this.codigoSC,
        produto: this.produto,
        fornecedor: this.fornecedor,
        comprador: this.comprador,
        observacao: this.observacao,
        notaFiscal: this.notaFiscal,
        lancadoNota: this.lancadoNota,
      }
    },
  },
}
</script>

<style scoped>

</style>
