<template>
  <div>
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <v-btn
          v-if="$auth.hasPermission('tela.criar.solicitacao.produto')"
          class="mr-3"
          color="primary"
          dark
          @click="addNew"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
          color="primary"
          dark
          class="mr-3"
          title="Remover Filtros"
          @click="removeFiltros"
      >
        <v-icon>mdi-filter-remove</v-icon>
      </v-btn>
      <HeaderControl v-model="model" :headers="headersOptions" local-storage-save-name="solicitacoes-produtos2"></HeaderControl>
      <Exportar pdf excel :filtro="filtroBusca" url-pdf="/reports/listagem-solicitacoes/relatorio-pdf" relatorioExcel="ListagemSolicitacoesCompras"></Exportar>
    </v-toolbar>
  </div>
</template>

<script>
import { getToken } from '@/core/services/storage.service'

export default {
  props: {
    value: {
      type: Object,
    },
    filtro: {
      type: Object,
    },
  },
  data: (instance) => {
    return {
      headers: [],
      headersOptions: [
        { value: 'data-table-expand', align: 'right p-0', class: 'sticky-header custom-table--header', total: 'title' },
        { text: 'Tipo', value: 'TipoOcSc', class: 'sticky-header custom-table--header', sortable: false, ativado: true },
        { text: 'Situação', value: 'Etapa', class: 'sticky-header custom-table--header', obrigatorio: true, total: 'count', totalFormat: (total) => total + ' item(ns)' },
        { text: 'Produção', value: 'producao', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Cod. SC', value: 'codigo_sc', class: 'sticky-header custom-table--header', obrigatorio: true },
        { text: 'Cod. OC', value: 'codigo_oc', class: 'sticky-header custom-table--header', obrigatorio: true },
        { text: 'Data SC', value: 'data_emissao_sc', class: 'sticky-header custom-table--header', align: 'center', obrigatorio: true },
        { text: 'Data OC', value: 'data_emissao_oc', class: 'sticky-header custom-table--header', align: 'center', obrigatorio: true },
        { text: 'Últ. Aprovacão', value: 'last_datapr', class: 'sticky-header custom-table--header', align: 'center', ativado: true },
        { text: 'Últ. Aprovador', value: 'last_abrnap', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Prox. Aprovador', value: 'next_abrnap', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Data Aprv. SC', value: 'data_aprovacao_sc', class: 'sticky-header custom-table--header', align: 'center', ativado: false },
        { text: 'Data Aprv. Nv. 1', value: 'data_aprovacao_nivel_1_oc', class: 'sticky-header custom-table--header', align: 'center', ativado: false },
        { text: 'Data Aprv. Nv. 2', value: 'data_aprovacao_nivel_2_oc', class: 'sticky-header custom-table--header', align: 'center', ativado: false },
        { text: 'Data Compra', value: 'data_compra_oc', class: 'sticky-header custom-table--header', align: 'center', ativado: true },
        { text: 'Data Entrega', value: 'data_entrega', class: 'sticky-header custom-table--header', align: 'center', ativado: true },
        { text: 'Data Vencimento NF', value: 'data_vencimento_nf', class: 'sticky-header custom-table--header', align: 'center', ativado: true },
        { text: 'Numero da NF', value: 'numero_nf', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Itens', value: 'itens_total', align: 'right', class: 'sticky-header custom-table--header', ativado: false, total: 'sum', totalFormat: (total) => total + ' item(ns)' },
        { text: 'Valor (R$)', value: 'valor_total', align: 'right', class: 'sticky-header custom-table--header', ativado: true, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 2) },
        { text: 'Solicitante', value: 'solicitante', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Código do Fornecedor', value: 'fornecedor_id', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Fornecedor', value: 'fornecedor', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Comprador', value: 'comprador', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Data Cancel.', value: 'data_cancelada_sc', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Data Emissão Nota', value: 'data_emissao_nf', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Quantidade Entregas', value: 'quantidade_entregas', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Quantidade Parcelas', value: 'quantidade_parcelas', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Desconto', value: 'desconto', align: 'right', class: 'sticky-header custom-table--header', ativado: false, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 2) },
        { text: 'Despesas', value: 'despesas', align: 'right', class: 'sticky-header custom-table--header', ativado: false, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 2) },
        { text: 'Frete', value: 'frete', align: 'right', class: 'sticky-header custom-table--header', ativado: false, total: 'sum', totalFormat: (total) => instance.$stringFormat.formatNumber(total, 2) },
      ],
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    filtroBusca () {
      return {
        data_inicial: this.filtro.dataInicial,
        data_final: this.filtro.dataFinal,
        empresa_id: this.filtro.empresa,
        producao_id: this.filtro.producao,
        unidade_negocio: this.filtro.unidadeNegocio,
        estagio_compra: this.filtro.estagioCompra,
        situacao: this.filtro.situacao,
        possui_cotacao: this.filtro.possuiCotacao,
        codigo_oc: this.filtro.codigoOC,
        codigo_sc: this.filtro.codigoSC,
        produto: this.filtro.produto,
        fornecedor: this.filtro.fornecedor,
        comprador: this.filtro.comprador,
        observacao: this.filtro.observacao,
        nota_fiscal: this.filtro.notaFiscal,
        lancado_nota: this.filtro.lancadoNota,
      }
    },
  },
  methods: {
    addNew () {
      const url = process.env.VUE_APP_NEW_URL +
          '/login/legacy-app?route=product-requests.create' +
          '&token=' + getToken()

      window.open(url, '_blank')
    },
    removeFiltros () {
      this.$router.push({ query: null })
      window.location.reload()
    },
  },
}
</script>

<style scoped>

</style>
