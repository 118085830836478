<template>
  <v-dialog v-model="model" max-width="800">
    <custom-snackbar
        ref="snackbar"
    />
    <v-form ref="form">
      <v-card>
        <v-toolbar color="info" dark>
          <span class="headline">Assumir Cotação dos Itens</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12 my-0 p-0">
                <v-data-table
                    v-model="selectedItems"
                    fixed-header
                    :headers="headersAssumirCotacao"
                    :items="itens"
                    :items-per-page="-1"
                    hide-default-footer
                    show-select
                    dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              text
              @click="model = false"
          >
            Cancelar
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="confirmarCotacao"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ApiService from '@/core/services/api.service'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    compra: Object,
    itens: Array,
  },
  data: (instance) => {
    return {
      dataEntregaParcial: instance.$date.getISOToday(),
      selectedItems: [],
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    headersAssumirCotacao () {
      return [
        { text: 'Produto', value: 'produto', sortable: false },
      ]
    },
  },
  methods: {
    confirmarCotacao () {
      if (!this.$refs.form.validate() ) {
        return false
      }

      this.model = false

      const itens = this.selectedItems

      if (itens.length === 0) {
        return false
      }

      this.$refs.snackbar.show('Requisitando', 'Assumindo Cotação dos itens', 'default', -1, true)

      ApiService
        .put('/compras/produtos/' + this.compra.compras_id + '/assumir_cotacao', { itens })
        .then((res) => {
          this.$refs.snackbar.close()

          if (!res.data.success) {
            this.$refs.snackbar.show('Cotação não assumida por erro', res.data.mensagem, 'warning', 3000, false)
            return
          }

          this.$emit('entregue')
          this.$refs.snackbar.show('Cotação assumida com sucesso', res.data.mensagem, 'success', 3000, false)
        })
        .catch(() => {
          this.$emit('entregue')
          this.$refs.snackbar.close()
          this.$refs.snackbar.show('Cotação não assumida por erro', 'Problema na comunicação', 'danger', 3000, false)
        })
    },
  },
}
</script>
